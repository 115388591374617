import React from "react";
// @mui/material components
import Grid from "@mui/material/Grid";
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, Stack, useMediaQuery, useTheme } from "@mui/material";

export function OrderECatalogue(props) {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const commonFormControlLabelSx = {
        '& .MuiFormControlLabel-label': {
            fontSize: {
                xs: '12px !important',
                sm: "14px !important"
            },
        },
    };

    return (
        <>
            {/* PhotoShoot Information Start */}
            <div className="border-2 border-gray-300 rounded-md mb-6">
                <div className="bg-gray-300 mb-4 px-4 py-2 border-b sm:px-6">
                    <h3 className="text-lg  text-center font-bold leading-6 text-gray-900">Photoshoot</h3>
                </div>
                <div className="px-6 pb-2 -mt-2">
                    <Grid container spacing={isSmallScreen ? 0.5 : 1}>
                        <Grid item xs={12} lg={2}>
                            <Stack spacing={0.5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECataloguePhotoShoot}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECataloguePhotoShoot"
                                            color="primary"
                                        />
                                    }
                                    label="Photoshoot"
                                    sx={commonFormControlLabelSx}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {/* PhotoShoot Information End */}


            {/* Fabric Layout Information Start */}
            <div className="border-2 border-gray-300 rounded-md mb-6">
                <div className="bg-gray-300 mb-4 px-4 py-2 border-b sm:px-6">
                    <h3 className="text-lg text-center font-bold leading-6 text-gray-900">Fabric Layout</h3>
                </div>

                <div className="px-6 pb-2 -mt-2">
                    <Grid container spacing={isSmallScreen ? 0.5 : 1}>
                        <Grid item xs={12} lg={2}>
                            <Stack spacing={0.5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECatalogueFabricLayout}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECatalogueFabricLayout"
                                            color="primary"
                                        />
                                    }
                                    label="Fabric Layout"
                                    sx={commonFormControlLabelSx}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {/* Fabric Layout Information End */}

            {/* Color Matching Information Start */}
            <div className="border-2 border-gray-300 rounded-md mb-6">
                <div className="bg-gray-300 mb-4 px-4 py-2 border-b sm:px-6">
                    <h3 className="text-lg text-center font-bold leading-6 text-gray-900">Color Matching</h3>
                </div>
                <div className="px-6 pb-2 -mt-2">
                    <Grid container spacing={isSmallScreen ? 0.5 : 1}>
                        <Grid item xs={12} lg={2}>
                            <Stack spacing={0.5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECatalogueColorMatching}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECatalogueColorMatching"
                                            color="primary"
                                        />
                                    }
                                    label="Color Matching"
                                    sx={commonFormControlLabelSx}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {/* Color Matching  Information End */}

            {/* Approval Information Start */}
            <div className="border-2 border-gray-300 rounded-md mb-6">
                <div className="bg-gray-300 mb-4 px-4 py-2 border-b sm:px-6">
                    <h3 className="text-lg text-center font-bold leading-6 text-gray-900">Approval</h3>
                </div>
                <div className="px-6 pb-2 -mt-2">
                    <Grid container spacing={isSmallScreen ? 0.5 : 1}>
                        <Grid item xs={12} lg={2}>
                            <Stack spacing={0.5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECatalogueApproval}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECatalogueApproval"
                                            color="primary"
                                        />
                                    }
                                    label="Approval"
                                    sx={commonFormControlLabelSx}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {/* Approval Information End */}

            {/* Mail Information Start */}
            <div className="border-2 border-gray-300 rounded-md mb-6">
                <div className="bg-gray-300 mb-4 px-4 py-2 border-b sm:px-6">
                    <h3 className="text-lg text-center font-bold leading-6 text-gray-900">Mail</h3>
                </div>
                <div className="px-6 pb-2 -mt-2">
                    <Grid container spacing={isSmallScreen ? 0.5 : 1}>
                        <Grid item xs={12} lg={1}>
                            <Stack spacing={0.5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECatalogueMail}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECatalogueMail"
                                            color="primary"
                                        />
                                    }
                                    label="Mail"
                                    sx={commonFormControlLabelSx}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </div>


            </div>
            {/* Mail Information End */}

            {/* Delivery Information Start */}
            <div className="border-2 border-gray-300 rounded-md mb-6">
                <div className="bg-gray-300 mb-4 px-4 py-2 border-b sm:px-6">
                    <h3 className="text-lg text-center font-bold leading-6 text-gray-900">Delivery</h3>
                </div>

                <div className="px-6 pb-2 -mt-2">
                    <Grid container spacing={isSmallScreen ? 0.5 : 1}>
                        <Grid item xs={12} lg={1}>
                            <Stack spacing={0.5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props?.orderModel?.ECatalogueDelivery}
                                            onChange={props?.handleCheckBoxes}
                                            name="ECatalogueDelivery"
                                            color="primary"
                                        />
                                    }
                                    label="Delivery"
                                    sx={commonFormControlLabelSx}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </div>


            </div>
            {/* Fitting Information End */}
        </>
    );
};