import React from "react";
import { Box, Button, Checkbox, DialogContent,  Grid,  Stack,  Tooltip,  useMediaQuery, useTheme } from '@mui/material';
import { VpnKey } from '@mui/icons-material';
import moment from "moment";
import { currentUser } from "services/user-service";
import { CheckboxGroup, CustomFormGroup, ReadOnlyInput, Section } from "./CommonOrderView";

function ViewOrderFile(props) {
    const { orderData, handleShowPrice, showPrice } = props;

    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const iMDScreen = useMediaQuery(theme.breakpoints.down('lg'));


    const fabricItems = [
        { label: "Full", value: orderData?.PBFull || "-" },
        { label: "Full Meter", value: orderData?.PBFullMtr || "-" },
        { label: "Half", value: orderData?.PBHalf || "-" },
        { label: "Half Meter", value: orderData?.PBHalfMtr || "-" },
        { label: "Total", value: orderData?.PBTotal || "-" },
        { label: "Total Meter", value: orderData?.PBTotalMtr || "-" },
    ];

    const cuttingItems = [
        { label: "Patta", value: orderData?.CuttingPatta || "-" },
        { label: "Tukda", value: orderData?.CuttingTukda || "-" },
        { label: "Table", value: orderData?.CuttingTable || "-" },
      ];

      const checkboxItems = [
        { label: "ZigZag", name: "TagZigZag", value: orderData?.TagZigZag },
        { label: "Interlock", name: "TagInterlock", value: orderData?.TagInterlock },
      ];
    
      const bindingColor = [
        { label: "Fabric Color", value: orderData?.BindingsFabricColor || "-" },
      ]
    
      const bidingFullDetail = [
        { label: "1.5", name: "TagZigZag", value: orderData?.Bindings15 },
        { label: "11", name: "TagInterlock", value: orderData?.Bindings11 },
        { label: "Full", name: "BindingsFull", value: orderData?.BindingsFull }
      ]

      const bindingItems = [
        { label: "Kaan", name: "BindingsKaan", value: orderData?.BindingsKaan || "-" },
        { label: "Patti", name: "BindingsPatti", value: orderData?.BindingsPatti || "-" },
        { label: "Dori", name: "BindingsDori", value: orderData?.BindingsDori || "-" },
        { label: "Corner", value: orderData?.BindingsCorner || "-" },
    ];

    const bindingItemsCheck = [
        { label: "Die Cut", name: "BindingsDieCut", value: orderData?.BindingsDieCut },
        { label: "Foil", name: "BindingsFoil", value: orderData?.BindingsFoil },
        { label: "Tag Printed", name: "BindingsTagPrinted", value: orderData?.BindingsTagPrinted },
        { label: "MDF", name: "BindingsMDF", value: orderData?.BindingsMDF },
        { label: "Cappa Board", name: "BindingsCappaBoard", value: orderData?.BindingsCappaBoard },
    ];

    const taggingItems = [
        { label: "Logo", name: "TagLogo", value: orderData?.TagLogo },
        { label: "Collection", name: "TagCollection", value: orderData?.TagCollection },
        { label: "Design", name: "TagDesign", value: orderData?.TagDesign },
        { label: "Shed", name: "TagShed", value: orderData?.TagShed },
        { label: "Instruction", name: "TagInstruction", value: orderData?.TagInstruction },
        { label: "Symbol", name: "TagSymbol", value: orderData?.TagSymbol },
        { label: "Sr No.", name: "TagSrNo", value: orderData?.TagSrNo },
        { label: `Width (${orderData?.TagWidthValue})`, name: "TagWidth", value: orderData?.TagWidth },
        { label: `Rubs (${orderData?.TaggingRubsValue})`, name: "TaggingRubs", value: orderData?.TaggingRubs },
        { label: `Weight (${orderData?.TaggingGSMValue})`, name: "TaggingGSM", value: orderData?.TaggingGSM },
    ];

    return (
        <DialogContent>
            <div className="bg-gray-300 border-gray-300 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-2 sm:px-6">
                    <h3 className="text-lg  text-center font-bold leading-6 text-gray-900">General Information</h3>
                </div>
                <div className="bg-white border-t border-gray-200">
                    <div className="px-6 py-4">
                        <Grid container spacing={isSmallScreen ? 0.5 : 1}>
                        <ReadOnlyInput label="Product" value={orderData?.ProductName} sm={6} lg={6} xs={12} />
                            <ReadOnlyInput label="Customer" value={orderData?.CustomerName} sm={6} lg={6} xs={12} />
                            <ReadOnlyInput label="Client" value={orderData?.Client} xs={12} lg={5} />
                            <ReadOnlyInput label="Order Date" value={moment(orderData?.Date).format('Do MMMM YYYY')} xs={12} lg={4} />
                            <ReadOnlyInput label="Quantity" sm={4} lg={3} xs={12} value={orderData?.Quantity} />
                            <ReadOnlyInput label="File Name" value={orderData?.FileName} xs={12} sm={7} lg={6} />
                            <ReadOnlyInput label="File Size" value={orderData?.FileSize} xs={6} sm={3} lg={4} />
                            <ReadOnlyInput label="IsRepeat?" lg={2} sm={2}  xs={6} value={orderData?.IsRepeat === 0 || null ? 'No' : 'Yes'} />
                        </Grid>
                    </div>
                </div>
            </div>

            <Section title="Party Fabric">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={fabricItems} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <ReadOnlyInput label="Remarks" xs={12} value={orderData?.PBRemarks || "-"} />
                    </Stack>
                </Grid>
            </Section>


            <Section title="Cutting">
                <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={cuttingItems} />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                        <CheckboxGroup items={checkboxItems} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <ReadOnlyInput label="Remarks" xs={12} value={orderData?.CuttingRemarks || "-"} />
                    </Stack>
                </Grid>
            </Section>

            <Section title="Binding">
                <Grid item xs={12} sm={5}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={bindingColor} />
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Stack spacing={0.5}>
                        <CheckboxGroup items={bidingFullDetail} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={bindingItems} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CheckboxGroup items={bindingItemsCheck} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <ReadOnlyInput label="Remarks" xs={12} value={orderData?.BindingsRemarks || "-"} />
                    </Stack>
                </Grid>
            </Section>

            <Section title="Tagging">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CheckboxGroup items={taggingItems} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <ReadOnlyInput label="Remarks" xs={12} value={orderData?.TagRemarks || "-"} />
                    </Stack>
                </Grid>
            </Section>

            <Section title="Photo Making">
                <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[{ label: "Photo Size", value: orderData?.PhotosSize || "-" }]} />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                        <CheckboxGroup items={[
                            { label: "1", value: orderData?.PhotosPage?.includes(1) ? true : false },
                            { label: "2", value: orderData?.PhotosPage?.includes(2) ? true : false },
                            { label: "3", value: orderData?.PhotosPage?.includes(3) ? true : false },
                            { label: "4", value: orderData?.PhotosPage?.includes(4) ? true : false }
                        ]} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CheckboxGroup items={[
                           { label: "Lamination", value: orderData?.PhotosLamination, name: "PhotosLamination" },
                           { label: "ColdPage", value: orderData?.PhotosColdPage, name: "PhotosColdPage" },
                           { label: "Matt", value: orderData?.PhotosMatt, name: "PhotosMatt" },
                           { label: "Glossy", value: orderData?.PhotosGlossy, name: "PhotosGlossy" }
                        ]} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <ReadOnlyInput label="Remarks" xs={12} value={orderData?.PhotosRemarks || "-"} />
                    </Stack>
                </Grid>
            </Section>

            <Section title="Screen Printing">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                             { label: "Top", value: orderData?.ScreenTop || "-" },
                             { label: "Bottom", value: orderData?.ScreenBottom || "-" },
                             { label: "Patti", value: orderData?.ScreenPatti || "-" },
                             { label: "Printed", value: orderData?.ScreenPrinted || "-", other : {checked : orderData?.ScreenPrintedBit} },
                        ]} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <ReadOnlyInput label="Remarks" xs={12} value={orderData?.ScreenRemarks || "-"} />
                    </Stack>
                </Grid>
            </Section>

            <Section title="Fittings">
                <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Step Gap", value: orderData?.FittingStepGap || "-" }
                        ]} />
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                        <CheckboxGroup items={[
                               { label: "1", value: orderData?.FittingNumber?.includes(1) ? true : false },
                               { label: "2", value: orderData?.FittingNumber?.includes(2) ? true : false },
                               { label: "3", value: orderData?.FittingNumber?.includes(3) ? true : false },
                               { label: "4", value: orderData?.FittingNumber?.includes(4) ? true : false }
                        ]} />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <ReadOnlyInput label="Remarks" xs={12} value={orderData?.FittingRemarks || "-"} />
                    </Stack>
                </Grid>
            </Section>

            <div className="bg-gray-300 shadow overflow-hidden sm:rounded-lg">
                <div className="bg-gray-300 px-4 py-2 border-b sm:px-6">
                    <h3 className="text-lg  text-center font-bold leading-6 text-gray-900">Extra</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500">Parcel Type:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.Parcel === 1 ? true : false} name="Parcel" color="primary" />Parcel</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.Loose === 1 ? true : false} name="Loose" color="primary" />Loose</span>
                            </dt>

                            <dt className="text-sm font-medium text-gray-500">Chalan Sequence:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.ChallanSequence1 === 1 ? true : false} name="ChallanSequence1" color="primary" />1</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.ChallanSequence3 === 1 ? true : false} name="ChallanSequence3" color="primary" />3</span>
                            </dt>
                        </div>
                    </dl>
                </div>
            </div>


            <div className="bg-gray-300 border-gray-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-2 sm:px-6">
                    <h3 className="text-lg  text-center font-bold leading-6 text-gray-900">Remarks</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.Remarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            {currentUser()?.Role === 'SUPER_ADMIN' &&
                <div className="bg-gray-300 border-gray-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-2 sm:px-6">
                        <h3 className="text-lg  text-center font-bold leading-6 text-gray-900">Unit Price</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6">
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                                    Price: {showPrice ? orderData?.UnitPrice : '****'}

                                    {!showPrice &&
                                        <Tooltip title="Verify to view price" placement="top" arrow>
                                            <Box className="bg-blue-500 text-white ml-4" aria-controls="simple-menu-1" aria-haspopup="true" onClick={handleShowPrice} size="small" component={Button}  >
                                                <VpnKey onClick={handleShowPrice} className="w-5 h-auto" />
                                            </Box>
                                        </Tooltip>
                                    }

                                </dd>

                            </div>
                        </dl>
                    </div>
                </div>
            }
        </DialogContent>
    );
};
export default ViewOrderFile;