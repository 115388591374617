import React from "react";
import Box from "@mui/material/Box";
import FilledInput from "@mui/material/FilledInput";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, InputLabel, MenuItem, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";

export function OrderFile(props) {

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const iMDScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const iXLScreen = useMediaQuery(theme.breakpoints.down('xl'));

  const commonFormControlLabelSx = {
    '& .MuiFormControlLabel-label': {
      fontSize: {
        xs: '12px !important', 
        sm : "14px !important"
      },
    },
  };
  return (
    <>
      {/* Party Fabric Information Start */}
      <div className="border-2 border-gray-300 rounded-md mb-6" >
        <div className="bg-gray-300 px-4 py-2 border-b sm:px-6">
          <h3 className="text-lg text-center font-bold leading-6 text-gray-900">Party Fabric</h3>
        </div>
        <div className="px-6 py-4">
          <Grid container spacing={isSmallScreen ? 0.5 : 1}>
            <Grid item xs={6} sm={4} lg={2} pr={1} pl={1} >
              <Stack spacing={0.5}>
                <InputLabel htmlFor="PBFull" className="text-xs sm:text-sm">Waterfall</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PBFull"
                  value={props?.orderModel?.PBFull}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} lg={2} pr={1} pl={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PBFGaze">F Gaze</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PBFGaze"
                  value={props?.orderModel?.PBFGaze}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} lg={2} pr={1} pl={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PBTGaze">T Gaze</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PBTGaze"
                  value={props?.orderModel?.PBTGaze}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} lg={2} pr={1} pl={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PBFullMtr">Full Meter</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PBFullMtr"
                  value={props?.orderModel?.PBFullMtr}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} lg={2} pr={1} pl={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PBHalf">Half</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PBHalf"
                  value={props?.orderModel?.PBHalf}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} lg={2} pr={1} pl={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PBHalfMtr">Half Meter</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PBHalfMtr"
                  value={props?.orderModel?.PBHalfMtr}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={6} lg={6} pr={1} pl={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PBTotal">Total Fabric</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PBTotal"
                  value={props?.orderModel?.PBTotal}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={6} lg={6} pr={1} pl={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PBTotalMtr">Total Meter</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PBTotalMtr"
                  value={props?.orderModel?.PBTotalMtr}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={12} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PBRemarks">Remarks</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PBRemarks"
                  value={props?.orderModel?.PBRemarks}
                  multiline
                  minRows={2}
                  maxRows={4}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* Party Fabric Information End */}


      {/* Cutting Information Start */}
      <div className="border-2 border-gray-300 rounded-md mb-6" >
        <div className="bg-gray-300 px-4 py-2 border-b sm:px-6">
          <h3 className="text-lg text-center font-bold leading-6 text-gray-900">Cutting</h3>
        </div>
        <div className="px-6 py-4">
          <Grid container spacing={isSmallScreen ? 0.5 : 1}>
            <Grid item xs={12} sm={4} lg={4} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="CuttingPatta">Patta</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="CuttingPatta"
                  value={props?.orderModel?.CuttingPatta}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4} lg={4} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="CuttingTukda">Tukda</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="CuttingTukda"
                  value={props?.orderModel?.CuttingTukda}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={4} lg={4} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="CuttingTable">Table</InputLabel>
                <Select value={props?.orderModel?.CuttingTable} name="CuttingTable"
                  onChange={e => props?.handleSelectChange('CuttingTable', e.target.value)}
                  sx={{
                    '& .MuiOutlinedInput-input': {
                      height: '35px !important',
                    },
                  }}
                >
                  <MenuItem value={null}>Select</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={4} lg={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props?.orderModel?.TagZigZag}
                    onChange={props?.handleCheckBoxes}
                    name="TagZigZag"
                    color="primary"
                  />
                }
                label={"ZigZag"}
                sx={commonFormControlLabelSx}
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props?.orderModel?.TagInterlock}
                    onChange={props?.handleCheckBoxes}
                    name="TagInterlock"
                    color="primary"
                  />
                }
                label="Interlock"
                sx={commonFormControlLabelSx}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="CuttingRemarks">Remarks</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="CuttingRemarks"
                  value={props?.orderModel?.CuttingRemarks}
                  multiline
                  minRows={2}
                  maxRows={4}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* Cutting Information End */}

      {/* Binding Information Start */}
      <div className="border-2 border-gray-300 rounded-md mb-6" >
        <div className="bg-gray-300 px-4 py-2 border-b sm:px-6">
          <h3 className="text-lg text-center font-bold leading-6 text-gray-900">Binding</h3>
        </div>
        <div className="px-6 py-4">
          <Grid container spacing={isSmallScreen ? 0.5 : 1}>
            <Grid item xs={12} lg={6}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="BindingsFabricColor">Fabric Color</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="BindingsFabricColor"
                  value={props?.orderModel?.BindingsFabricColor}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={4} lg={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props?.orderModel?.Bindings15}
                    onChange={props?.handleCheckBoxes}
                    name="Bindings15"
                    color="primary"
                  />
                }
                label="1.5"
                sx={commonFormControlLabelSx}
              />
            </Grid>
            <Grid item xs={4} lg={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props?.orderModel?.Bindings11}
                    onChange={props?.handleCheckBoxes}
                    name="Bindings11"
                    color="primary"
                  />
                }
                label="11"
                sx={commonFormControlLabelSx}
              />
            </Grid>
            <Grid item xs={4} lg={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props?.orderModel?.BindingsFull}
                    onChange={props?.handleCheckBoxes}
                    name="BindingsFull"
                    color="primary"
                  />
                }
                label="Full"
                sx={commonFormControlLabelSx}
              />
            </Grid>

            <Grid item xs={12} lg={3} pr={1} pl={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="BindingsKaan">Kaan</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="BindingsKaan"
                  value={props?.orderModel?.BindingsKaan}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={3} pr={1} pl={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="BindingsPatti">Patti</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="BindingsPatti"
                  value={props?.orderModel?.BindingsPatti}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={3} pr={1} pl={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="BindingsDori">Dori</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="BindingsDori"
                  value={props?.orderModel?.BindingsDori}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>

            <Grid item xs={12} lg={3} px={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="BindingsCorner">Corner</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="BindingsCorner"
                  value={props?.orderModel?.BindingsCorner}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Stack spacing={0.5}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem', flexWrap: 'wrap' }}>
                  <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: "0rem" }}>
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.BindingsDieCut}
                          onChange={props?.handleCheckBoxes}
                          name="BindingsDieCut"
                          color="primary"
                        />
                      }
                      label="Die Cut"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.BindingsFoil}
                          onChange={props?.handleCheckBoxes}
                          name="BindingsFoil"
                          color="primary"
                        />
                      }
                      label="Foil"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.BindingsTagPrinted}
                          onChange={props?.handleCheckBoxes}
                          name="BindingsTagPrinted"
                          color="primary"
                        />
                      }
                      label="Tag Printed"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.BindingsMDF}
                          onChange={props?.handleCheckBoxes}
                          name="BindingsMDF"
                          color="primary"
                        />
                      }
                      label="MDF"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.BindingsCappaBoard}
                          onChange={props?.handleCheckBoxes}
                          name="BindingsCappaBoard"
                          color="primary"
                        />
                      }
                      label="Cappa Board"
                      sx={commonFormControlLabelSx}
                    />
                  </FormGroup>
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="BindingsCorner">Remarks</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="BindingsRemarks"
                  value={props?.orderModel?.BindingsRemarks}
                  multiline
                  minRows={2}
                  maxRows={4}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
          </Grid>
        </div>

      </div>
      {/* Binding Information End */}

      {/* Tagging Information Start */}
      <div className="border-2 border-gray-300 rounded-md mb-6" >
        <div className="bg-gray-300 px-4 py-2 border-b sm:px-6">
          <h3 className="text-lg text-center font-bold leading-6 text-gray-900">Tagging</h3>
        </div>
        <div className="px-6 py-4">
          <Grid container spacing={isSmallScreen ? 0.5 : 1}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Stack spacing={0.5}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem', flexWrap: 'wrap' }}>
                  <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: "0rem" }}>
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.TagLogo}
                          onChange={props?.handleCheckBoxes}
                          name="TagLogo"
                          color="primary"
                        />
                      }
                      label="Logo"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.TagCollection}
                          onChange={props?.handleCheckBoxes}
                          name="TagCollection"
                          color="primary"
                        />
                      }
                      label="Collection"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.TagDesign}
                          onChange={props?.handleCheckBoxes}
                          name="TagDesign"
                          color="primary"
                        />
                      }
                      label="Design"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.TagShed}
                          onChange={props?.handleCheckBoxes}
                          name="TagShed"
                          color="primary"
                        />
                      }
                      label="Shed"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.TagInstruction}
                          onChange={props?.handleCheckBoxes}
                          name="TagInstruction"
                          color="primary"
                        />
                      }
                      label="Instruction"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.TagSymbol}
                          onChange={props?.handleCheckBoxes}
                          name="TagSymbol"
                          color="primary"
                        />
                      }
                      label="Symbol"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      // className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.TagSrNo}
                          onChange={props?.handleCheckBoxes}
                          name="TagSrNo"
                          color="primary"
                        />
                      }
                      label="Sr No."
                      sx={commonFormControlLabelSx}
                    />
                  </FormGroup>
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={4} >
              <Stack spacing={0.5}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem', flexWrap: 'wrap' }}>
                  <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: "0rem" }}>
                    <Box>
                      <FormControlLabel
                        className="w-[86px]"
                        control={
                          <Checkbox
                            checked={props?.orderModel?.TagWidth}
                            onChange={props?.handleCheckBoxes}
                            name="TagWidth"
                            color="primary"
                          />
                        }
                        label="Width"
                        sx={commonFormControlLabelSx}
                      />
                      <TextField
                        variant="filled"
                        width="100%"
                        marginBottom="1rem!important"
                        autoComplete="off"
                        name="TagWidthValue"
                        value={props?.orderModel?.TagWidthValue}
                        onChange={e => props?.handleChange(e)}
                        sx={{
                          '& .MuiFilledInput-input': {
                            height: 35,
                          },
                        }}
                        size="small"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          autoComplete="off"
                          type="text"
                        />
                      </TextField>
                    </Box>

                  </FormGroup>
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <Stack spacing={0.5}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem', flexWrap: 'wrap' }}>
                  <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: "0rem" }}>
                    <Box>
                      <FormControlLabel
                        className="w-[86px]"
                        control={
                          <Checkbox
                            checked={props?.orderModel?.TaggingGSM}
                            onChange={props?.handleCheckBoxes}
                            name="TaggingGSM"
                            color="primary"
                          />
                        }
                        label="Weight"
                        sx={commonFormControlLabelSx}
                      />
                      <TextField
                        variant="filled"
                        marginBottom="1rem!important"
                        autoComplete="off"
                        name="TaggingGSMValue"
                        value={props?.orderModel?.TaggingGSMValue}
                        onChange={e => props?.handleChange(e)}
                        sx={{
                          '& .MuiFilledInput-input': {
                            height: 35,
                          },
                        }}
                        size="small"
                      >
                        <Box
                          paddingLeft="0.75rem"
                          paddingRight="0.75rem"
                          component={FilledInput}
                          autoComplete="off"
                          type="text"
                        />
                      </TextField>
                    </Box>
                  </FormGroup>
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
              <Stack spacing={0.5}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem', flexWrap: 'wrap' }}>
                  <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: "0rem" }}>
                    <FormControlLabel
                      className="w-[86px]"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.TaggingRubs}
                          onChange={props?.handleCheckBoxes}
                          name="TaggingRubs"
                          color="primary"
                        />
                      }
                      label="Rubs"
                      sx={commonFormControlLabelSx}
                    />
                    <TextField
                      variant="filled"
                      width="100%"
                      marginBottom="1rem!important"
                      autoComplete="off"
                      name="TaggingRubsValue"
                      value={props?.orderModel?.TaggingRubsValue}
                      onChange={e => props?.handleChange(e)}
                      sx={{
                        '& .MuiFilledInput-input': {
                          height: 35,
                        },
                      }}
                      size="small"
                    >
                      <Box
                        paddingLeft="0.75rem"
                        paddingRight="0.75rem"
                        component={FilledInput}
                        autoComplete="off"
                        type="text"
                      />
                    </TextField>
                  </FormGroup>
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="TagRemarks">Remarks</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="TagRemarks"
                  value={props?.orderModel?.TagRemarks}
                  multiline
                  minRows={2}
                  maxRows={4}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* Tagging Information End */}

      {/* Photos Information Start */}
      <div className="border-2 border-gray-300 rounded-md mb-6" >
        <div className="bg-gray-300 px-4 py-2 border-b sm:px-6">
          <h3 className="text-lg text-center font-bold leading-6 text-gray-900">Photo Making</h3>
        </div>
        <div className="px-6 py-4">
          <Grid container spacing={isSmallScreen ? 0.5 : 1}>
            <Grid item xs={12} md={12} lg={3} xl={3} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PhotosSize">Photo Size</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PhotosSize"
                  value={props?.orderModel?.PhotosSize}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={9} xl={9} sx={theme => ({
              [theme.breakpoints.up("lg")]: {
                mt: 2
              }
            })}>
              <FormGroup style={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : "row", alignItems: isSmallScreen ? 'flex-start' : "center", marginBottom: "0rem" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props?.orderModel?.PhotosLamination}
                      onChange={props?.handleCheckBoxes}
                      name="PhotosLamination"
                      color="primary"
                    />
                  }
                  label="Lamination"
                  sx={commonFormControlLabelSx}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props?.orderModel?.PhotosColdPage}
                      onChange={props?.handleCheckBoxes}
                      name="PhotosColdPage"
                      color="primary"
                    />
                  }
                  label="Cold Page"
                  sx={commonFormControlLabelSx}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props?.orderModel?.PhotosMatt}
                      onChange={props?.handleCheckBoxes}
                      name="PhotosMatt"
                      color="primary"
                    />
                  }
                  label="Matt"
                  sx={commonFormControlLabelSx}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props?.orderModel?.PhotosGlossy}
                      onChange={props?.handleCheckBoxes}
                      name="PhotosGlossy"
                      color="primary"
                    />
                  }
                  label="Glossy"
                  sx={commonFormControlLabelSx}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12}>
              <Stack spacing={0.5}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem', flexWrap: 'wrap' }}>
                  <Typography className="font-bold " style={{ marginRight: '1rem' }}>Page:</Typography>
                  <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: "0rem" }}>
                    <FormControlLabel
                      className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.PhotosPageArry?.includes(1)}
                          onChange={props?.handleMultiCheckBoxes}
                          value={1}
                          name="PhotosPage"
                          color="primary"
                        />
                      }
                      label="1"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.PhotosPageArry?.includes(2)}
                          onChange={props?.handleMultiCheckBoxes}
                          value={2}
                          name="PhotosPage"
                          color="primary"
                        />
                      }
                      label="2"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.PhotosPageArry?.includes(3)}
                          onChange={props?.handleMultiCheckBoxes}
                          value={3}
                          name="PhotosPage"
                          color="primary"
                        />
                      }
                      label="3"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      className="sm:ml-2"
                      control={
                        <Checkbox
                          checked={props?.orderModel?.PhotosPageArry?.includes(4)}
                          onChange={props?.handleMultiCheckBoxes}
                          value={4}
                          name="PhotosPage"
                          color="primary"
                        />
                      }
                      label="4"
                      sx={commonFormControlLabelSx}
                    />
                  </FormGroup>
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="PhotosRemarks">Remarks</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="PhotosRemarks"
                  value={props?.orderModel?.PhotosRemarks}
                  multiline
                  minRows={2}
                  maxRows={4}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
          </Grid>
        </div>


      </div>
      {/* Photos Information End */}

      {/* Screen Printing Information Start */}
      <div className="border-2 border-gray-300 rounded-md mb-6" >
        <div className="bg-gray-300 px-4 py-2 border-b sm:px-6">
          <h3 className="text-lg text-center font-bold leading-6 text-gray-900">Screen Printing</h3>
        </div>
        <div className="py-4 px-6">
          <Grid container spacing={isSmallScreen ? 0.5 : 1}>
            <Grid item xs={6} sm={6} lg={3} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="ScreenTop">Top</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="ScreenTop"
                  value={props?.orderModel?.ScreenTop}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} lg={3} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="ScreenBottom">Bottom</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="ScreenBottom"
                  value={props?.orderModel?.ScreenBottom}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={5.5} lg={2.5} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="ScreenPatti">Patti</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="ScreenPatti"
                  value={props?.orderModel?.ScreenPatti}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={11} sm={5.5} lg={2.5} px={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="ScreenPrinted">Printed</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="ScreenPrinted"
                  value={props?.orderModel?.ScreenPrinted}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={1} sm={1} lg={1} className="pt-4 sm:pt-6">
              <FormControlLabel
                className=""
                control={
                  <Checkbox
                    checked={props?.orderModel?.ScreenPrintedBit}
                    onChange={props?.handleCheckBoxes}
                    name="ScreenPrintedBit"
                    color="primary"
                  />
                }
                label=""
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Stack>
                <InputLabel className="text-xs sm:text-sm" htmlFor="ScreenRemarks">Remarks</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="ScreenRemarks"
                  value={props?.orderModel?.ScreenRemarks}
                  multiline
                  minRows={2}
                  maxRows={4}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px",
                    },
                  }}
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
          </Grid>

        </div>


      </div>
      {/* Screen Printing Information End */}

      {/* Fitting Information Start */}
      <div className="border-2 border-gray-300 rounded-md mb-6" >
        <div className="bg-gray-300 px-4 py-2 border-b sm:px-6">
          <h3 className="text-lg text-center font-bold leading-6 text-gray-900">Fittings</h3>
        </div>
        <div className="py-5 px-4">
          <Grid container spacing={isSmallScreen ? 0.5 : 1}>
            <Grid item xs={12} lg={4} pl={1} pr={1}>
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="FittingStepGap">Step Gap</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="FittingStepGap"
                  value={props?.orderModel?.FittingStepGap}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      height: 35,
                      fontSize: isSmallScreen ? "12px" : "14px"
                    },
                  }}
                  size="small"
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} md={12} lg={8} sx={{mt : iMDScreen ? 0 : 2.5, ml : iMDScreen ? 1 : 0}}>
              <Stack spacing={0.5}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem', flexWrap: 'wrap' }}>
                  <FormGroup style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: "0rem" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props?.orderModel?.FittingNumberArry?.includes(1)}
                          onChange={props?.handleMultiCheckBoxes}
                          value={1}
                          name="FittingNumber"
                          color="primary"
                        />
                      }
                      label="1"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props?.orderModel?.FittingNumberArry?.includes(2)}
                          onChange={props?.handleMultiCheckBoxes}
                          value={2}
                          name="FittingNumber"
                          color="primary"
                        />
                      }
                      label="2"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props?.orderModel?.FittingNumberArry?.includes(3)}
                          onChange={props?.handleMultiCheckBoxes}
                          value={3}
                          name="FittingNumber"
                          color="primary"
                        />
                      }
                      label="3"
                      sx={commonFormControlLabelSx}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={props?.orderModel?.FittingNumberArry?.includes(4)}
                          onChange={props?.handleMultiCheckBoxes}
                          value={4}
                          name="FittingNumber"
                          color="primary"
                        />
                      }
                      label="4"
                      sx={commonFormControlLabelSx}
                    />
                  </FormGroup>
                </div>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={12} >
              <Stack spacing={0.5}>
                <InputLabel className="text-xs sm:text-sm" htmlFor="FittingRemarks">Remarks</InputLabel>
                <TextField
                  variant="filled"
                  width="100%"
                  marginBottom="1rem!important"
                  autoComplete="off"
                  name="FittingRemarks"
                  value={props?.orderModel?.FittingRemarks}
                  multiline
                  minRows={2}
                  maxRows={4}
                  onChange={e => props?.handleChange(e)}
                  sx={{
                    '& .MuiFilledInput-input': {
                      fontSize: isSmallScreen ? "12px" : "14px",
                      height: 35,
                    },
                  }}
                >
                  <Box
                    paddingLeft="0.75rem"
                    paddingRight="0.75rem"
                    component={FilledInput}
                    autoComplete="off"
                    type="text"
                  />
                </TextField>
              </Stack>
            </Grid>
          </Grid>
        </div>


      </div>
      {/* Fitting Information End */}
    </>
  );
};