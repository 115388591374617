import React from "react";
import { Box, Button, Checkbox, DialogContent, Grid, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { VpnKey } from '@mui/icons-material';
import moment from "moment";
import { currentUser } from "services/user-service";
import { CustomFormGroup, ReadOnlyInput, Section } from "./CommonOrderView";

function ViewECatalogue(props) {
    const { orderData, handleShowPrice, showPrice } = props;

    const theme = useTheme();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const iMDScreen = useMediaQuery(theme.breakpoints.down('lg'));
    return (
        <DialogContent>
            <div className="bg-gray-300 border-gray-300 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-2 sm:px-6">
                    <h3 className="text-lg text-center font-bold leading-6 text-gray-900">General Information</h3>
                </div>
                <div className="bg-white border-t border-gray-200">
                    <div className="px-6 py-4">
                        <Grid container spacing={isSmallScreen ? 0.5 : 1}>
                            <ReadOnlyInput label="Product" value={orderData?.ProductName} sm={6} lg={6} xs={12} />
                            <ReadOnlyInput label="Customer" value={orderData?.CustomerName} sm={6} lg={6} xs={12} />
                            <ReadOnlyInput label="Client" value={orderData?.Client} xs={12} lg={5} />
                            <ReadOnlyInput label="Order Date" value={moment(orderData?.Date).format('Do MMMM YYYY')} xs={12} lg={4} />
                            <ReadOnlyInput label="Quantity" sm={4} lg={3} xs={12} value={orderData?.Quantity} />
                            <ReadOnlyInput label="File Name" value={orderData?.FileName} xs={12} sm={7} lg={6} />
                            <ReadOnlyInput label="File Size" value={orderData?.FileSize} xs={6} sm={3} lg={4} />
                            <ReadOnlyInput label="IsRepeat?" lg={2} sm={2} xs={6} value={orderData?.IsRepeat === 0 || null ? 'No' : 'Yes'} />
                        </Grid>
                    </div>
                </div>
            </div>

            <Section title="Photoshoot">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Photoshoot", value: orderData?.ECataloguePhotoShoot || "-" },
                        ]} />
                    </Stack>
                </Grid>
            </Section>
            <Section title="Fabric Layout">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Fabric Layout", value: orderData?.ECatalogueFabricLayout || "-" },
                        ]} />
                    </Stack>
                </Grid>
            </Section>
            <Section title="Color Matching">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Color Matching", value: orderData?.ECatalogueColorMatching || "-" },
                        ]} />
                    </Stack>
                </Grid>
            </Section>
            <Section title="Approval">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Approval", value: orderData?.ECatalogueApproval || "-" },
                        ]} />
                    </Stack>
                </Grid>
            </Section>
            <Section title="Mail">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Mail", value: orderData?.ECatalogueMail || "-" },
                        ]} />
                    </Stack>
                </Grid>
            </Section>
            <Section title="Delivery">
                <Grid item xs={12}>
                    <Stack spacing={0.5}>
                        <CustomFormGroup items={[
                            { label: "Delivery", value: orderData?.ECatalogueDelivery || "-" },
                        ]} />
                    </Stack>
                </Grid>
            </Section>
            <div className="bg-gray-300 shadow overflow-hidden sm:rounded-lg">
                <div className="bg-gray-300 px-4 py-2 border-b sm:px-6">
                    <h3 className="text-lg  text-center font-bold leading-6 text-gray-900">Extra</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 items-center">
                            <dt className="text-sm font-medium text-gray-500">Parcel Type:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.Parcel === 1 ? true : false} name="Parcel" color="primary" />Parcel</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.Loose === 1 ? true : false} name="Loose" color="primary" />Loose</span>
                            </dt>

                            <dt className="text-sm font-medium text-gray-500">Chalan Sequence:
                                <span className="md:border-r border-red-500 pr-3"> <Checkbox checked={orderData?.ChallanSequence1 === 1 ? true : false} name="ChallanSequence1" color="primary" />1</span>
                                <span className="md:border-r border-red-500 pr-3"><Checkbox checked={orderData?.ChallanSequence3 === 1 ? true : false} name="ChallanSequence3" color="primary" />3</span>
                            </dt>
                        </div>
                    </dl>
                </div>
            </div>


            <div className="bg-gray-300 border-gray-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-2 sm:px-6">
                    <h3 className="text-lg  text-center font-bold leading-6 text-gray-900">Remarks</h3>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6">
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0">{orderData?.Remarks}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            {currentUser()?.Role === 'SUPER_ADMIN' &&
                <div className="bg-gray-300 border-gray-300 mb-4 mt-4 border-2 shadow overflow-hidden sm:rounded-lg">
                    <div className="px-4 py-2 sm:px-6">
                        <h3 className="text-lg  text-center font-bold leading-6 text-gray-900">Unit Price</h3>
                    </div>
                    <div className="border-t border-gray-200">
                        <dl>
                            <div className="bg-white px-4 py-3 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6">
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                                    Price: {showPrice ? orderData?.UnitPrice : '****'}

                                    {!showPrice &&
                                        <Tooltip title="Verify to view price" placement="top" arrow>
                                            <Box className="bg-blue-500 text-white ml-4" aria-controls="simple-menu-1" aria-haspopup="true" onClick={handleShowPrice} size="small" component={Button}  >
                                                <VpnKey onClick={handleShowPrice} className="w-5 h-auto" />
                                            </Box>
                                        </Tooltip>
                                    }

                                </dd>

                            </div>
                        </dl>
                    </div>
                </div>
            }
        </DialogContent>
    );
};
export default ViewECatalogue;